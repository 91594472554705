


































































































































































































import { getLogs } from '../../api/admin';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mdiRefresh, mdiMagnify, mdiOpenInNew } from '@mdi/js';
import {
  StromeePlusLogGroup,
  LogEntry,
  StromeeLogGroup
} from '../../typings';
import { noop } from 'vue-class-component/lib/util';
import { Hub } from '@aws-amplify/core';

@Component
export default class LogTableView extends Vue {
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiOpenInNew: string = mdiOpenInNew;
  private readonly startOffsets: Array<{ value: number }> = [
    1, 5, 10, 30, 60, 360, 720, 1440, 10080, 43200
  ].map((value: number): { value: number } => ({ value }));
  private readonly defaultStartOffset: number = 60;
  private readonly noop: typeof noop = noop;

  private loading: boolean = true;
  private sortBy: string = 'timestamp';
  private sortDesc: boolean = true;
  private tableItems: LogEntry[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    width?: string | number;
    align?: 'start' | 'center' | 'end';
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.log.TIMESTAMP'),
        value: 'timestamp',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.log.MESSAGE'),
        value: 'message',
        sortable: false
      },
      {
        text: '',
        value: 'link',
        sortable: false,
        width: '0',
        align: 'end'
      }
    ];
  }

  @Prop({
    type: String,
    required: true,
    validator: (value: string): boolean =>
      Object.values(StromeePlusLogGroup).includes(
        value as StromeePlusLogGroup
      ) || Object.values(StromeeLogGroup).includes(value as StromeeLogGroup)
  })
  private readonly logGroup!: StromeePlusLogGroup | StromeeLogGroup;

  @Prop({ type: [String, Boolean], default: false })
  private readonly searchTable!: string | false;

  @Prop({ type: [Number, Boolean], default: 5 })
  private readonly startOffset!: number | false;

  @Watch('startOffset')
  onStartOffsetChange(): void {
    this.getLogs();
  }

  private mounted(): void {
    this.getLogs();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getLogs(): void {
    this.loading = true;
    getLogs(this.logGroup, this.startOffset || this.defaultStartOffset)
      .then((list: LogEntry[]): void => void (this.tableItems = list))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
