var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",staticStyle:{"min-width":"50vw","width":"100%","height":"100%"}},[_c('v-subheader',{staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.log.TABLE_HEADER'))+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-0",style:({
        transition: 'max-width 0.5s ease-in-out',
        maxWidth: _vm.searchTable !== false ? '300px' : 0
      }),attrs:{"value":_vm.searchTable || '',"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.log.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.log.SEARCH'),"type":"search"},on:{"input":function($event){_vm.$router
          .replace({
            query: Object.assign({}, _vm.$route.query, {search: ($event || '').trim()})
          })
          .catch(_vm.noop)},"blur":function($event){_vm.searchTable ||
          _vm.$router
            .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
            .catch(_vm.noop)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$router
          .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
          .catch(_vm.noop)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pa-3"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"0"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){_vm.searchTable !== false ||
                  _vm.$router
                    .replace({
                      query: Object.assign({}, _vm.$route.query, {search: ''})
                    })
                    .catch(_vm.noop)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.log.SEARCH'))+" ")])])]},proxy:true}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getLogs}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.log.REFRESH'))+" ")])])],1),_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
        ? 'height: calc(100vh - 64px - 48px); position: relative;'
        : 'height: calc(100% - 48px); position: relative')},[_c('v-data-table',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'position: absolute; inset: 0' : ''),attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.tableItems,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","search":_vm.searchTable || '',"custom-filter":function (value, search, item) { return JSON.stringify(item).toUpperCase().includes(search.toUpperCase()); },"fixed-header":"","mobile-breakpoint":"0","height":"calc(100vh - 64px - 48px - 59px)","items-per-page":50,"footer-props":{
        itemsPerPageOptions: [50]
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"v-data-footer__select"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.log.TIMESPAN'))+" "),_c('v-select',{staticStyle:{"min-width":"100px"},attrs:{"value":_vm.startOffset || _vm.defaultStartOffset,"items":_vm.startOffsets,"item-text":function (item) { return _vm.$moment.duration(item.value, 'minutes').humanize(); },"hide-details":""},on:{"input":function($event){_vm.$router
                .replace({
                  query: Object.assign({}, _vm.$route.query,
                    {start:
                      $event === _vm.defaultStartOffset
                        ? undefined
                        : $event.toString()})
                })
                .catch(_vm.noop)}}})],1)]},proxy:true},{key:"item.timestamp",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.timestamp))+" ")]}},{key:"item.message",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.message),function(messagePart,index){return [(typeof messagePart === 'string')?_c('div',{key:index},[_vm._v(" "+_vm._s(messagePart)+" ")]):_c('highlightjs',{key:index,attrs:{"language":"json","code":JSON.stringify(messagePart, undefined, 2)}})]})]}},{key:"item.link",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","href":item.link,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.log.OPEN_CLOUDWATCH'))+" ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }